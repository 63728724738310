import * as _ from 'lodash'
import { ReplaySubject, BehaviorSubject, defer } from 'rxjs'
import { tap, distinctUntilChanged } from 'rxjs/operators'
import { AppDrawerCfg, Breakpoints } from '../../types/interfaces'

export class AppFrameStateService {
  private drawerOpen$ = new BehaviorSubject<boolean>(false)

  private breakpoint$ = new BehaviorSubject<Breakpoints>(undefined)

  private appDrawerCfg$ = new BehaviorSubject<AppDrawerCfg>(undefined)

  private appDrawerCfg: AppDrawerCfg

  constructor() {
    console.log(`creating appFrameState Service`)
  }

  observeDrawerOpen() {
    return defer(() => this.drawerOpen$.pipe(distinctUntilChanged()))
  }

  getDrawerOpen() {
    return this.drawerOpen$.getValue()
  }

  observeBreakpoint() {
    return defer(() => this.breakpoint$.pipe(distinctUntilChanged()))
  }

  getBreakpoint() {
    return this.breakpoint$.getValue()
  }

  observeAppDrawerCfg() {
    return defer(() => this.appDrawerCfg$.pipe(distinctUntilChanged(_.isEqual)))
  }

  getAppDrawerCfg() {
    return this.appDrawerCfg$.getValue()
  }

  setActiveCfg(cfg: { bkp: Breakpoints; appDrawerCfg: AppDrawerCfg }) {
    const { bkp, appDrawerCfg } = cfg
    this.breakpoint$.next(bkp)
    this.appDrawerCfg$.next(appDrawerCfg)
    this.appDrawerCfg = appDrawerCfg
    console.log(
      `appFrameState bkp=${bkp} drawer=${
        appDrawerCfg.variant
      } open=${this.drawerOpen$.getValue()}`
    )
    if (['disabled'].includes(this.appDrawerCfg.variant)) {
      this.drawerOpen$.next(false)
    }
    if (['permanent'].includes(this.appDrawerCfg.variant)) {
      this.drawerOpen$.next(true)
    }
  }

  toggleDrawer() {
    // toggle on hamburger click if temporary or persistent
    if (['temporary', 'persistent'].includes(this.appDrawerCfg.variant)) {
      this.drawerOpen$.next(!this.drawerOpen$.getValue())
    }
  }

  tryToggleDrawer() {
    // toggle on any menu click (other than hamburgerss) only if temporary
    if (['temporary'].includes(this.appDrawerCfg.variant)) {
      this.drawerOpen$.next(!this.drawerOpen$.getValue())
    }
  }
}
