import {
  DependencyInjector,
  useInjectorHook,
  InjectionToken,
  useObservable,
  Provider,
} from '@mindspace-io/react'

import { Query } from '../../types/gatsby-graphql-types'

import { AllSvgService, SvgTitleByPath, SvgByPath } from './allSvg'

export type { SvgProps } from './allSvg'
export { hastParse } from './hastParse'

export const allSvgProvider: Provider = {
  provide: new InjectionToken<AllSvgService>('AllSvgToken'),
  useClass: AllSvgService,
}

export type UseAllSvgResponse = [
  SvgByPath,
  (query: Query, titles: SvgTitleByPath) => void
]

export function getUseAllSvgService(injector: DependencyInjector) {
  const useAllSvgService: () => UseAllSvgResponse = () => {
    const [service] = useInjectorHook(allSvgProvider.provide, injector)
    const [svgByPath] = useObservable<SvgByPath>(service.svgByPath$)
    const setQueryResult = (query: Query, titles: SvgTitleByPath) =>
      service.setQuery(query, titles)
    return [svgByPath, setQueryResult]
  }
  return useAllSvgService
}
