import * as React from 'react'
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import { LinkProps } from '../../../types/interfaces'
import { useNavStyles } from '../LayoutMUI/AppFrameStyle'

export interface NavItemProps {
  title?: string
  Icon?: React.FunctionComponent
  href?: string
  depth: number
  maxDepth?: number
  collapseDepth?: number
  openImmediately?: boolean
  topLevel?: boolean
  onClick?: () => void
  mini?: boolean
  divider?: boolean
  children?: React.ReactNode
  alignItems?: 'flex-start' | 'flex-end' | 'center'
}
export const getNavItem: (cfg: {
  Link: React.FunctionComponent<LinkProps>
}) => React.FunctionComponent<NavItemProps> = cfg => {
  const { Link } = cfg
  return React.useCallback(
    (props: NavItemProps) => {
      const {
        children,
        depth,
        collapseDepth,
        href,
        openImmediately = false,
        topLevel = false,
        mini = false,
        divider = false,
        title,
        Icon,
        onClick,
      } = props
      const classes = useNavStyles(props)
      const theme = useTheme()
      const [open, setOpen] = React.useState(openImmediately)
      const collapse = collapseDepth && depth >= collapseDepth
      const handleClick = () => {
        setOpen(!open)
        if (onClick) {
          onClick()
        }
      }

      const style = {
        paddingLeft: theme.spacing(2 * depth),
      }
      const button = (
        <>
          {' '}
          {Icon && title ? (
            <Button
              className={classNames(classes.buttonLeaf, `depth-${depth}`)}
              disableTouchRipple
              onClick={handleClick}
              startIcon={React.createElement(Icon)}
              aria-label={title}
            >
              {title}
            </Button>
          ) : null}
          {Icon && !title ? (
            <IconButton
              className={classNames(classes.buttonLeaf, `depth-${depth}`)}
              disableTouchRipple
              onClick={handleClick}
              size="medium"
              color="inherit"
              aria-label={Icon.displayName}
            >
              <Icon />
            </IconButton>
          ) : null}
          {!Icon ? (
            <Button
              className={classNames(classes.buttonLeaf, `depth-${depth}`)}
              disableTouchRipple
              onClick={handleClick}
              aria-label={title}
            >
              {title}
            </Button>
          ) : null}
        </>
      )
      return (
        <ListItem
          className={classes.itemLeaf}
          disableGutters
          style={style}
          divider={divider}
          dense={mini}
        >
          {href ? (
            <Link to={href} underline="none" activeClassName="active">
              {button}
            </Link>
          ) : (
            button
          )}
          {children && collapse ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {children}
            </Collapse>
          ) : null}
          {children && !collapse ? children : null}
        </ListItem>
      )
    },
    [Link]
  )
}
