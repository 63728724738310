import {
  DependencyInjector,
  useInjectorHook,
  InjectionToken,
  useObservable,
  Provider,
} from '@mindspace-io/react'

import { Query } from '../../types/gatsby-graphql-types'

import { PageProps } from '../../types/interfaces'
import { PageContext } from './PageContext'
import { AllPageService, PageBySlug } from './allPage'
import { allMdxProvider } from '../allMdx'

export type { PageContext } from './PageContext'
export type { PageBySlug } from './allPage'

export const allPageProvider: Provider = {
  provide: new InjectionToken<AllPageService>('AllPageToken'),
  useClass: AllPageService,
  deps: [allMdxProvider.provide],
}

export type UseAllPageResponse = {
  activePage: PageContext
  mainPages: PageContext[]
  pageBySlug: PageBySlug
  setQueryResult: (query: Query, mainMenuPages: PageContext[]) => void
}

export function getUseAllPageService(injector: DependencyInjector) {
  const UseAllPageService: (
    location?: PageProps
  ) => UseAllPageResponse = location => {
    const [service] = useInjectorHook(allPageProvider.provide, injector)
    const [activePage] = useObservable<PageContext>(
      service.observeActivePage(location)
    )
    const [pageBySlug] = useObservable<PageBySlug>(service.observePageBySlug())
    const [mainPages] = useObservable<PageContext[]>(service.observeMainPages())
    const setQueryResult = (query: Query, mainMenuPages: PageContext[]) =>
      service.setQuery(query, mainMenuPages)
    return {
      activePage,
      mainPages: mainPages || [],
      pageBySlug,
      setQueryResult,
    }
  }
  return UseAllPageService
}
