import * as React from 'react'
import * as _ from 'lodash'

import { graphql, useStaticQuery } from 'gatsby'

import { useAllPage } from '@cbeyond/ui-kit'
import { Query } from '../../types/gatsby-graphql-types'
import { mainPages } from '../../layouts/appFrameCfg'

export const query = graphql`
  fragment SitePageInfo on SitePageEdge {
    node {
      id
      path
      context {
        frontmatter {
          title
        }
      }
    }
  }
`

export function useAllPageQuery(data: Query) {
  const [ready, setReady] = React.useState(false)
  const { setQueryResult } = useAllPage()
  const doFetch = React.useCallback(() => {
    setReady(true)
    setQueryResult(data, mainPages)
  }, [])

  React.useEffect(() => {
    if (!ready && data) {
      doFetch()
    }
  }, [ready])
}

export const AllPageQuery: React.FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSitePage {
          edges {
            ...SitePageInfo
          }
        }
      }
    `
  )
  useAllPageQuery(data)
  return null
}
