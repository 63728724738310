import * as React from 'react'
import { Breakpoints, AppFrameCfg } from '../types/interfaces'

import { useBkpConditions } from './useBkpConditions'

export const useActiveBkp = (
  appFrameCfg: Partial<Record<Breakpoints, AppFrameCfg>>
) => {
  const [active, setActive] = React.useState(null)
  const bkpList = Object.keys(appFrameCfg) as Breakpoints[]
  const allCondsByBkp = useBkpConditions({
    bkpList,
    negate: false,
    mediaQuery: true,
  })
  React.useEffect(() => {
    const matches: { bkp: Breakpoints; match: boolean }[] = []
    Object.entries(allCondsByBkp).forEach(([bkp, matchesForBkp]) => {
      matches.push({
        match: matchesForBkp.every((c: boolean) => c),
        bkp: bkp as Breakpoints,
      })
    })
    const activeList = matches.filter(b => b.match)
    if (activeList.length === 0) {
      console.log(
        `no active breakpoint found in list ${JSON.stringify(bkpList)}`
      )
      return
    }
    if (activeList.length > 1) {
      console.log(
        `multiple active breakpoints found ${JSON.stringify(
          activeList.map(s => s.bkp)
        )}`
      )
      return
    }
    // console.log(`active breakpoint ${activeList[0].bkp}`)
    setActive(activeList[0].bkp)
  }, [allCondsByBkp])
  return active as Breakpoints
}
