import { ReplaySubject } from 'rxjs'
import { MdxCtl } from '../allMdx'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export interface PageContext {
  pathname: string
  title?: string
  Icon?: React.FunctionComponent
  when?: 'authenticated' | 'unauthenticated'
  children?: PageContext[]
  parent?: PageContext
  route?: boolean
  mdxCtl?: MdxCtl
}

export class PageContextImpl implements PageContext {
  pathname: string

  title?: string

  Icon?: React.FunctionComponent

  when?: 'authenticated' | 'unauthenticated'

  children?: PageContext[]

  parent?: PageContext

  mdxCtl?: MdxCtl

  route = true

  isMdx = false

  private pageContext$: ReplaySubject<PageContext>

  // note on pageContext$:
  // the pattern is to emit each time an attribute is updated
  // In our case this happens only once at construction when the graphql
  // query is handled. But this pattern allows for dynamic updates in the future.

  constructor(pathname: string) {
    this.pathname = pathname
    this.pageContext$ = new ReplaySubject<PageContext>(1)
  }

  observe() {
    return this.pageContext$.asObservable()
  }

  configPage(props: Omit<PageContext, 'pathname'>) {
    Object.assign(this, props)
    this.notifyObservers()
  }

  notifyObservers() {
    this.pageContext$.next(this as PageContext)
  }
}
