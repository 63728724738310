import { withPrefix } from 'gatsby'
import { Link, SEO, getLayoutMUI as getLayout } from '@cbeyond/ui-kit'
import { TopLayout } from './TopLayout'
import { useAppFrameCfg } from './appFrameCfg'

// this layout is loaded by gatsby-plugin-layout
// we want to use this layout plugin for all layout context that must persist
// through the component mounting lifecyle
// see details at: https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
export default TopLayout
export const Layout = getLayout({
  Link,
  SEO,
  withPrefix,
  useAppFrameCfg
})
