/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { uid } from 'react-uid'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, Typography, Divider } from '@material-ui/core'
import { Link } from '../ui/Link'
import { MdxCtl, useAllPage, PageContext } from '../../services'

const caretRight = '"\\25B8"'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    color: theme.typography.h1.color,
    '& li': {
      '&:before': {
        content: caretRight,
        paddingRight: '8px',
      },
    },
  },
}))

export interface TocProps extends React.ComponentPropsWithRef<'ul'> {
  order: string[]
}
export const Toc: (
  mdx: MdxCtl
) => React.FunctionComponent<TocProps> = mdx => props => {
  const classes = useStyles(props)
  const { activePage } = useAllPage({ location: { pathname: mdx.slug } })
  return (
    <Typography variant="h5">
      {/* <List {...props} className={classes.root}>
        {mdx.sections.map((c: MdxCtl, idx: number) => (
          <ListItem key={uid(c, idx)}>
            <Link to={c.slug}>{c.title}</Link>
          </ListItem>
        ))}
      </List>
      <Divider /> */}
      {activePage ? (
        <List {...props} className={classes.root}>
          {activePage.children.map((p: PageContext, idx: number) => (
            <ListItem key={uid(p, idx)}>
              <Link to={p.pathname}>{p.title}</Link>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Typography>
  )
}
