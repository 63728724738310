import * as React from 'react'
import { Typography, Box, Paper } from '@material-ui/core'
import { withPrefix } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import { useTheme } from '@material-ui/core/styles'
import { LayoutProps, LinkProps } from '../../../types/interfaces'
import {
  MDXLayoutComponents,
  MDXGlobalComponents,
  mdxLayoutStyles,
} from '../../mdx'

import { useMdxCtl } from '../../../services'

export interface LayoutMdxProps extends LayoutProps {
  pageContext: {
    frontmatter: { title?: string; date?: string }
  }
}

export const getLayoutMdx: (
  Layout: React.FunctionComponent<LayoutProps>,
  Link: React.FunctionComponent<LinkProps>,
  classes?: (
    props: any
  ) => Record<
    'gridList' | 'table' | 'paper' | 'header' | 'em' | 'li' | 'hr' | 'a',
    string
  >
) => React.FunctionComponent<LayoutMdxProps> = (Layout, Link, classes) =>
  React.useCallback(props => {
    const { location, children } = props
    let { pathname } = location
    const theme = useTheme()
    const mdxClasses = classes ? classes(props) : mdxLayoutStyles(props)
    pathname = `/${pathname.replace(withPrefix('/'), '')}`
    const [mdxCtl] = useMdxCtl({ slug: pathname })
    if (!mdxCtl) {
      return null
    }
    const { title } = mdxCtl
    return (
      <Layout location={location} title={title}>
        <Paper className="mdx-root">
          {title ? (
            <>
              <Typography
                variant="h1"
                style={{
                  // color: theme.palette.primary.contrastText,
                  // backgroundColor: theme.palette.primary.dark,
                  overflowWrap: 'break-word',
                  textAlign: 'center',
                  padding: theme.spacing(2, 0),
                }}
              >
                {title}
              </Typography>
              <Box p={1} />
            </>
          ) : null}
          <MDXProvider
            components={{
              ...MDXLayoutComponents(mdxClasses, Link),
              ...MDXGlobalComponents(mdxCtl),
            }}
          >
            {children}
          </MDXProvider>
        </Paper>
      </Layout>
    )
  }, [])
