import * as _ from 'lodash'
import * as React from 'react'
import { useObservable } from '@mindspace-io/react'

import { BehaviorSubject } from 'rxjs'
import { SvgProps, hastParse } from '../allSvg'

const hastGenerate = (svg: SvgProps) => {
  const hastElems = hastParse(svg.hast.children[0].children)
  return hastElems
}

/**
 * Use RxJS to push svg html AST changes to UI
 *
 * The BehaviorSubject stores the more recent, last emitted value...
 * We also use `useObservable(service.name$)` hook to watch
 * for new 'hast' values
 *
 * BTW - this is called a 'push-based' API
 */

// export interface SvgProps {
//   id: string
//   path: string
//   sourceInstanceName: string
//   viewBox: string
//   title?: string
//   caption?: string
//   Svg: (p: React.SVGProps<SVGSVGElement>) => JSX.Element
//   content: string
//   hastElems: React.ReactNode[]
// }

type HastElemsById = {
  [id: string]: {
    subject: BehaviorSubject<React.ReactNode>
    fetched: boolean
  }
}
export class SvgHastService {
  private hastElemsById: HastElemsById = {}

  // constructor() {
  //   console.log(`SvgHastClass:: new Instance`)
  // }

  useHtmlAST(svgp: SvgProps) {
    if (!(svgp.id in this.hastElemsById)) {
      this.hastElemsById[svgp.id] = {
        subject: new BehaviorSubject<React.ReactNode>(null),
        fetched: false,
      }
    }
    const [hastElems] = useObservable<React.ReactNode>(
      this.hastElemsById[svgp.id].subject // .pipe(shareReplay(2))
    )
    return hastElems
  }

  fetch(svgp: SvgProps) {
    if (svgp.id in this.hastElemsById) {
      if (!this.hastElemsById[svgp.id].fetched) {
        this.hastElemsById[svgp.id].subject.next(hastGenerate(svgp))
        this.hastElemsById[svgp.id].fetched = true
      }
    }
  }
}
