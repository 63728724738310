import * as _ from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { takeWhile } from 'rxjs/operators'

import { SitePageEdge, Query } from '../../types/gatsby-graphql-types'
import { PageProps } from '../../types/interfaces'
import { PageContext, PageContextImpl } from './PageContext'
import { AllMdxService } from '../allMdx/allMdx'
import { MdxCtl } from '../allMdx'

export type PageBySlug = {
  [slug: string]: PageContext
}

type PageBySlugImpl = {
  [slug: string]: PageContextImpl
}

export class AllPageService {
  private pageBySlug: PageBySlugImpl = {}

  private mainPages: PageContext[] = []

  private pageBySlug$: BehaviorSubject<PageBySlugImpl>

  private mainPages$: BehaviorSubject<PageContext[]>

  constructor(private allMdx: AllMdxService) {
    this.pageBySlug$ = new BehaviorSubject<PageBySlugImpl>(this.pageBySlug)
    this.mainPages$ = new BehaviorSubject<PageContext[]>(this.mainPages)
  }

  observePageBySlug() {
    return this.pageBySlug$.asObservable()
  }

  observeMainPages() {
    return this.mainPages$.asObservable()
  }

  observeActivePage(props: PageProps) {
    const { location } = props || {}
    const { pathname = '/' } = location || {}
    let page
    if (!(pathname in this.pageBySlug)) {
      page = new PageContextImpl(pathname)
      this.pageBySlug[pathname] = page
    } else {
      page = this.pageBySlug[pathname]
    }
    return page.observe()
  }

  setQuery(query: Query, mainPages: PageContext[] = []) {
    // setQuery shall be invoked only once when layout is created
    // however with storybook we have concurrent storybook decorators and hence the pages
    // may be initialised multiple times.
    if (this.mainPages.length > 0) {
      return
    }
    query.allSitePage.edges.forEach((edge: SitePageEdge) => {
      const path = _.get(edge, 'node.path', null)
      const isMdx: boolean =
        _.get(edge, 'node.context.frontmatter', null) !== null
      const page =
        path in this.pageBySlug
          ? this.pageBySlug[path]
          : new PageContextImpl(path)
      if (!(path in this.pageBySlug)) {
        this.pageBySlug[path] = page
      }
      const mainPage = mainPages.filter(p => path === p.pathname)
      if (mainPage.length > 0) {
        const { pathname, ...others } = mainPage[0]
        page.configPage(others)
      }
      page.isMdx = isMdx
    })
    Object.values(this.pageBySlug).forEach(page => {
      if (page.isMdx) {
        this.allMdx
          .observeMdxCtl({ slug: page.pathname })
          .pipe(takeWhile(m => !m, true)) // take until m is undefined with inclusive=true
          .subscribe((m: MdxCtl) => {
            if (!m) {
              return
            }
            page.configPage({
              title: page.title ? page.title : m.title, // do not overwrite the page title if already defined
              route: m.route,
              mdxCtl: m,
              parent:
                m.parent?.slug in this.pageBySlug
                  ? this.pageBySlug[m.parent.slug]
                  : null,
              children: m.sections?.flatMap(c => {
                if (c.slug in this.pageBySlug) {
                  return [this.pageBySlug[c.slug]]
                }
                console.error(
                  `UseAllPage.setQuery error: mdx slug ${c.slug} not in site page paths`
                )
                return []
              }),
            })
          })
      }
    })
    mainPages.forEach(p => {
      if (!(p.pathname in this.pageBySlug)) {
        console.error(
          `UseAllPage.setQuery error: could not find a page at path '${p.pathname}'`
        )
      }
      this.mainPages.push(this.pageBySlug[p.pathname])
    })
    this.pageBySlug$.next(this.pageBySlug)
    this.mainPages$.next(this.mainPages)
  }
}
