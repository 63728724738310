import * as _ from 'lodash'
import * as React from 'react'
import { uid } from 'react-uid'
import { HASTElementProps } from 'svg-parser'

export const hastParse = (elems: HASTElementProps[]) => {
  const hastElems: React.ReactNode[] = []
  elems.forEach(el => {
    const { children: hastChildren = [], properties = {} } = el
    const hastChildElems: React.ReactNode[] = []
    hastChildren.forEach(c => hastChildElems.push(hastParse([c])))
    const { style, ...others } = properties
    const styleObj: { [prop: string]: string } = {}
    if (style) {
      // console.log(`${el.tagName} ${style}`)
      style.split(';').forEach(styleProps => {
        const keyvalue = styleProps.split(':')
        const obj = JSON.parse(
          `{"${_.camelCase(keyvalue[0])}":"${keyvalue[1]}"}`
        )
        Object.assign(styleObj, obj)
      })
    }
    const renamed = {}
    _.mapKeys(others, (v, k) => {
      let nk = _.camelCase(k)
      if (nk === 'class') {
        nk = 'className'
      }
      _.assign(renamed, { [nk]: v })
    })
    if (el.type === 'element') {
      hastElems.push(
        React.createElement(
          el.tagName,
          { key: uid(el, hastElems.length), style: styleObj, ...renamed },
          hastChildElems
        )
      )
    } else if (el.type === 'text') {
      hastElems.push(el.value.replace('&amp;', '&'))
    } else {
      hastElems.push(<em>svg hast parser unknowm element type {el.type}</em>)
    }
  })
  return hastElems
}
