import * as _ from 'lodash'
import * as React from 'react'

const ContactUsButton = React.lazy(() => import('./ContactUsButton'))

export interface ContactUsProps {
  title: React.ReactNode | string
  color?: 'primary' | 'secondary'
}

// Make user click a button to show email adderss via lazy loading
export const ContactUs: React.FunctionComponent<ContactUsProps> = props => {
  const { title, color } = props

  const isSSR = typeof window === 'undefined'
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<>loading...</>}>
          <ContactUsButton title={title} color={color} />
        </React.Suspense>
      )}
    </>
  )
}
