import {
  DependencyInjector,
  useInjectorHook,
  InjectionToken,
  Provider,
  useObservable,
} from '@mindspace-io/react'
import { Breakpoints, AppDrawerCfg, AppBarCfg } from '../../types/interfaces'

import { AppFrameStateService } from './appFrameState'

export type UseAppFrameStateResponse = {
  drawerOpen: boolean
  breakpoint: Breakpoints
  appDrawerCfg: AppDrawerCfg // currently active appDrawer cfg

  toggleDrawer: () => void // used by hamburger or close icons
  tryToggleDrawer: () => void // used by menu clicks

  // setActiceCfg invoked by AppFrame when a new breakpoint is detected
  setActiveCfg: (cfg: { bkp: Breakpoints; appDrawerCfg: AppDrawerCfg }) => void
}

export const appFrameStateProvider: Provider = {
  provide: new InjectionToken<AppFrameStateService>('AppFrameStateToken'),
  useClass: AppFrameStateService,
}

export function getUseAppFrameStateService(injector: DependencyInjector) {
  const useAppFrameState: () => UseAppFrameStateResponse = () => {
    const [service] = useInjectorHook(appFrameStateProvider.provide, injector)
    const [drawerOpen] = useObservable<boolean>(
      service.observeDrawerOpen(),
      service.getDrawerOpen()
    )
    const [breakpoint] = useObservable<Breakpoints>(
      service.observeBreakpoint(),
      service.getBreakpoint()
    )
    const [appDrawerCfg] = useObservable<AppDrawerCfg>(
      service.observeAppDrawerCfg(),
      service.getAppDrawerCfg()
    )
    const setActiveCfg = (cfg: {
      bkp: Breakpoints
      appDrawerCfg: AppDrawerCfg
    }) => service.setActiveCfg(cfg)
    const toggleDrawer = () => service.toggleDrawer()
    const tryToggleDrawer = () => service.tryToggleDrawer()

    return {
      drawerOpen,
      breakpoint,
      appDrawerCfg,
      setActiveCfg,
      toggleDrawer,
      tryToggleDrawer,
    }
  }
  return useAppFrameState
}
