import { makeInjector, DependencyInjector } from '@mindspace-io/react'

import { allPageProvider, getUseAllPageService } from './allPage'

import {
  allMdxProvider,
  getUseAllMdxService,
  getUseMdxCtlService,
} from './allMdx'
import { allSvgProvider, getUseAllSvgService } from './allSvg'
import { svgHastProvider, getUseSvgHastService } from './svgHast'
import {
  appFrameStateProvider,
  getUseAppFrameStateService,
} from './appFrameState'

export type { PageContext, PageBySlug, UseAllPageResponse } from './allPage'
export type { MdxCtl, UseAllMdxResponse } from './allMdx'
export type { SvgProps, UseAllSvgResponse } from './allSvg'
export type { UseSvgHastResponse } from './svgHast'
export type { UseAppFrameStateResponse } from './appFrameState'

const injector: DependencyInjector = makeInjector([
  // { provide: API_KEY, useValue: '123456' },
  allPageProvider,
  allMdxProvider,
  allSvgProvider,
  svgHastProvider,
  appFrameStateProvider,
])

export const useAllPage = getUseAllPageService(injector)
export const useAllMdx = getUseAllMdxService(injector)
export const useMdxCtl = getUseMdxCtlService(injector)
export const useAllSvg = getUseAllSvgService(injector)
export const useSvgHast = getUseSvgHastService(injector)
export const useAppFrameState = getUseAppFrameStateService(injector)
