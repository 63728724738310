import * as React from 'react'
import * as _ from 'lodash'

import { graphql, useStaticQuery } from 'gatsby'

import { useAllMdx } from '@cbeyond/ui-kit'
// import { imageInfoDict } from '../../../tools/image-list'
import { Query } from '../../types/gatsby-graphql-types'

export const query = graphql`
  fragment MdxInfo on MdxEdge {
    node {
      id
      fileAbsolutePath
      excerpt(pruneLength: 160)
      tableOfContents
      timeToRead
      fields {
        title
        slug
        uid
        route
        category
        sourceInstanceName
        relativePath
      }
      frontmatter {
        date
      }
    }
  }
`

export function useAllMdxQuery(data: Query) {
  const [ready, setReady] = React.useState(false)
  const [mdxBySlug, setQueryResult] = useAllMdx()

  const doFetch = React.useCallback(() => {
    setReady(true)
    setQueryResult(data)
  }, [])

  React.useEffect(() => {
    if (!ready && data) {
      doFetch()
    }
  }, [ready])

  return [mdxBySlug]
}

export const AllMdxQuery: React.FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(sort: { fields: [fields___uid], order: ASC }) {
          edges {
            ...MdxInfo
          }
        }
      }
    `
  )
  useAllMdxQuery(data)
  return null
}
