module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1035}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-smartypants"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Villa Clementine","short_name":"Villa Clementine","start_url":"/","background_color":"#f7f7f7","theme_color":"#191919","display":"minimal-ui","icon":"./src/assets/img/favicon.comp.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"53de0c37336a6f9ac5bc891563498af5"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
