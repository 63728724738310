import {
  DependencyInjector,
  useInjectorHook,
  InjectionToken,
  Provider,
} from '@mindspace-io/react'

import { SvgProps } from '../allSvg'
import { SvgHastService } from './svgHast'

export type UseSvgHastResponse = [
  // (svgp: SvgProps) => Observable<React.ReactNode>,
  React.ReactNode,
  () => void
]

export const svgHastProvider: Provider = {
  provide: new InjectionToken<SvgHastService>('AllSvgToken'),
  useClass: SvgHastService,
}

export function getUseSvgHastService(injector: DependencyInjector) {
  const useSvgHast: (svgp: SvgProps) => UseSvgHastResponse = svgp => {
    const [service] = useInjectorHook(svgHastProvider.provide, injector)
    const fetch = () => service.fetch(svgp)
    return [service.useHtmlAST(svgp), fetch]
  }
  return useSvgHast
}
