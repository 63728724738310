import {
  DependencyInjector,
  useInjectorHook,
  InjectionToken,
  useObservable,
  Provider,
} from '@mindspace-io/react'

import { Mdx, Query } from '../../types/gatsby-graphql-types'

import { MdxCtl } from './MdxCtl'
import { MdxBySlug, AllMdxService } from './allMdx'

export type { MdxCtl } from './MdxCtl'

export const allMdxProvider: Provider = {
  provide: new InjectionToken<AllMdxService>('AllMdxToken'),
  useClass: AllMdxService,
}

export type UseAllMdxResponse = [MdxBySlug, (query: Query) => void]

export function getUseAllMdxService(injector: DependencyInjector) {
  const UseAllMdxService: () => UseAllMdxResponse = () => {
    const [service] = useInjectorHook(allMdxProvider.provide, injector)
    const [mdxBySlug] = useObservable<MdxBySlug>(
      service.observeMdxBySlug(),
      service.getMdxBySlug()
    )
    const setQueryResult = (query: Query) => service.setQuery(query)
    return [mdxBySlug, setQueryResult]
  }
  return UseAllMdxService
}

export function getUseMdxCtlService(injector: DependencyInjector) {
  const UseMdxCtlService: (props: {
    mdx?: Mdx
    slug?: string
  }) => [MdxCtl] = props => {
    const [service] = useInjectorHook(allMdxProvider.provide, injector)
    const [mdxCtl] = useObservable<MdxCtl>(
      service.observeMdxCtl(props),
      service.getMdxCtl(props)
    )
    return [mdxCtl]
  }
  return UseMdxCtlService
}
