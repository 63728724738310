/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden, { HiddenProps } from '@material-ui/core/Hidden'
import { uid } from 'react-uid'
import {
  LayoutProps,
  LayoutCfgProps,
  Breakpoints,
} from '../../../types/interfaces'
import { getAppBar } from './AppBar'
import { getAppDrawer } from './AppDrawer'
import { useBkpConditions } from '../../../hooks/useBkpConditions'
import { useActiveBkp } from '../../../hooks/useActiveBkp'
import { useAppFrameStyles } from './AppFrameStyle'
import { useAppFrameState } from '../../../services'

// const AppSearch = React.lazy(() => import('./AppSearch'))
// function DeferredAppSearch() {
//   const [mounted, setMounted] = React.useState(false)
//   React.useEffect(() => {
//     setMounted(true)
//   }, [])

//   return (
//     <>
//       <link
//         rel="preload"
//         href="https://cdn.jsdelivr.net/docsearch.js/2/docsearch.min.css"
//         as="style"
//       />
//       {/* Suspense isn't supported for SSR yet */}
//       {mounted ? (
//         <React.Suspense fallback={null}>
//           <AppSearch />
//         </React.Suspense>
//       ) : null}
//     </>
//   )
// }

export const getAppFrame: (
  cfg: LayoutCfgProps
) => React.FunctionComponent<LayoutProps> = cfg => {
  const { SEO, useAppFrameCfg } = cfg
  return (props: LayoutProps) => {
    const AppBar = getAppBar(cfg)
    const AppDrawer = getAppDrawer(cfg)
    const { children, location, title: pageTitle, ...seoProps } = props
    const classes = useAppFrameStyles(props)
    const appFrameCfg = useAppFrameCfg(props)
    const {
      appDrawerCfg: activeAppDrawerCfg,
      setActiveCfg,
    } = useAppFrameState()
    const activeBkp = useActiveBkp(appFrameCfg)
    const allCondsByBkp = React.useCallback(useBkpConditions, [appFrameCfg])({
      bkpList: Object.keys(appFrameCfg) as Breakpoints[],
      negate: true,
      mediaQuery: false,
    }) as Partial<Record<Breakpoints, string[]>>
    const hiddenProps: Partial<Record<Breakpoints, HiddenProps>> = {}
    Object.entries(allCondsByBkp).forEach(([bkp, hiddenBkpList]) => {
      const p: HiddenProps = {}
      hiddenBkpList.forEach(hiddenBkp => {
        p[hiddenBkp as Breakpoints] = true
      })
      hiddenProps[bkp as Breakpoints] = p
    })
    React.useEffect(() => {
      // console.log(`useEffect on ${active}`)
      if (activeBkp) {
        const appDrawerCfg = appFrameCfg[activeBkp]?.appDrawer ?? null
        if (appDrawerCfg) {
          setActiveCfg({ bkp: activeBkp, appDrawerCfg })
        }
      }
    }, [activeBkp, appFrameCfg])

    if (!activeAppDrawerCfg) {
      return null
    }
    return (
      <div className={classes.root}>
        <CssBaseline />
        <SEO title={pageTitle} {...seoProps} />
        {Object.entries(appFrameCfg).map(([bkp, frameCfg]) => {
          const { appBarTop, appBarBottom, appDrawer } = frameCfg
          const hidden = hiddenProps[bkp as Breakpoints]
          const Drawer = appDrawer ? (
            <AppDrawer location={location} {...appDrawer} />
          ) : null
          const AppBarTop = appBarTop ? (
            <AppBar location={location} {...appBarTop}>
              {Drawer}
              {children}
            </AppBar>
          ) : null
          const AppBarBottom = appBarBottom ? (
            <AppBar location={location} {...appBarBottom}>
              {appBarTop ? null : (
                <>
                  {Drawer}
                  {children}
                </>
              )}
            </AppBar>
          ) : null
          return (
            <Hidden key={uid(frameCfg)} {...hidden}>
              {AppBarTop}
              {AppBarBottom}
            </Hidden>
          )
        })}
      </div>
    )
  }
}
