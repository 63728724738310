import * as React from 'react'
import classNames from 'classnames'

import * as lodash from 'lodash'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  SiteConfig,
  PageProps,
  PageContext,
  AppDrawerCfg,
  AppBarCfg,
  UseAppFrameCfg,
  Footer as FooterComp,
  getNavMenu,
  Link,
  useAppFrameState
} from '@cbeyond/ui-kit'
import HomeIcon from '@material-ui/icons/Home'
import { Brand } from './Brand'
import { BackgroundImg } from '../components/BackgroundImg'

const Footer = (_: PageProps) => <FooterComp brand={<Brand type="footer" />} org={<SiteConfig.Org />} />
const Header = BackgroundImg

const HomeBrandIcon = (_: PageProps) => (
  <Link underline="none" to="/">
    <Button>
      <Brand type="header" />
    </Button>
  </Link>
)
const Hamburger = (props: PageProps) => {
  // const { onDrawerOpen, onDrawerClose, drawerOpen } = props
  const { drawerOpen, toggleDrawer } = useAppFrameState()
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={toggleDrawer}
      edge="start"
      className={classNames('hamburger', 'hamburger--vortex', 'js-hamburger', drawerOpen && 'is-active')}
    >
      <span className={classNames('hamburger-box')}>
        <span className={classNames('hamburger-inner')} />
      </span>
    </IconButton>
  )
}

export const mainPages: PageContext[] = [
  {
    pathname: '/',
    title: 'ACCUEIL'
  },
  {
    pathname: '/visite/',
    title: 'VISITE-GUIDEE'
  },
  {
    pathname: '/surfaces/',
    title: 'SURFACES'
  },
  {
    pathname: '/carte/',
    title: 'CARTE'
  },
  {
    pathname: '/contact-us/',
    title: 'CONTACT'
  }
]
/**
 * <Playground>
 */
export const useAppFrameCfg: UseAppFrameCfg = (props: PageProps) => {
  const pathname = lodash.get(props, 'location.pathname', null)
  const appBar = (appBarContent: 'nav' | 'hamburger') =>
    ({
      anchor: 'top',
      variant: 'dense',
      hideOnScrollDown: false,
      elevateOnScroll: false,
      backToTop: false,
      Footer,
      Header,
      content: {
        left: [HomeBrandIcon],
        right: appBarContent === 'nav' ? [getNavMenu({ Link, direction: 'row' })] : [Hamburger]
      }
    } as AppBarCfg)
  const appDrawer = (drawerVariant: 'disabled' | 'permanent' | 'persistent' | 'temporary') => {
    let rootPath = pathname.split('/')[1] ?? null
    rootPath = rootPath ? `/${rootPath}/` : null
    const mini = false
    // const drawerVariant = lodash.get(drawerVariants, `${mediaSize}${isDoc ? 'Doc' : ''}`, undefined)
    return {
      anchor: 'left',
      alignItems: 'center',
      clipped: true,
      mini,
      divider: false,
      content: {
        top: [
          getNavMenu({
            className: 'main-menu',
            divider: true,
            mini: false,
            alignItems: 'flex-end',
            Link
          })
        ]
      },
      variant: drawerVariant
    } as AppDrawerCfg
  }
  return {
    // https://material-ui.com/components/hidden/#how-it-works

    // appFrame breakpoints define the visible portions (opposite of hidden)

    // innerWidth  |xs      sm       md       lg       xl
    //             |--------|--------|--------|--------|-------->
    // width       |   xs   |   sm   |   md   |   lg   |   xl
    // smUp        |   false | true
    // mdDown      |                     true | false

    // smDown      |           true  | false  | false
    // mdDown      |           true  | true   | false
    // lgUp        |           false | false  | true
    // Important declare breakpoints in growing order of breakpoints
    //      (or else the computed hidden properties will be wrong)
    // smDown: {
    //   appBarTop: appBar('hamburger'),
    //   appDrawer: appDrawer('temporary')
    // },
    mdDown: {
      appBarTop: appBar('hamburger'),
      appDrawer: appDrawer('temporary')
    },
    lgUp: {
      appBarTop: appBar('nav'),
      appDrawer: appDrawer('disabled')
    }
  }
}
