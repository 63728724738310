import { makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 240
export const useAppFrameStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: (props: { drawerClipped?: boolean }) =>
      props.drawerClipped ? theme.zIndex.drawer + 1 : theme.zIndex.appBar,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  bottom: {
    top: 'auto',
    bottom: 0,
  },
  shiftRight: {
    marginLeft: drawerWidth,
  },
  shiftLeft: {
    marginRight: drawerWidth,
  },
  toolbar: {
    minHeight: '50px',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  scrollTop: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: (props: { drawerAnchor?: 'left' | 'right' }) =>
      props.drawerAnchor === 'left' ? drawerWidth : 'auto',
    marginRight: (props: { drawerAnchor?: 'left' | 'right' }) =>
      props.drawerAnchor === 'right' ? drawerWidth : 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  persistentCloseIcon: {
    '& svg': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  drawerContent: {
    flexFlow: 'column nowrap',
    flexGrow: 1,
    display: 'flex',
    alignItems: (props: {
      alignItems?: 'flex-start' | 'center' | 'flex-end'
    }) => props.alignItems || 'flex-start',
    justifyContent: 'flex-start',
    '& hr': {
      alignSelf: 'stretch',
      margin: theme.spacing(1, 0),
    },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: (props: { drawerAnchor?: 'left' | 'right' }) =>
      props.drawerAnchor === 'left' ? drawerWidth : undefined,
    marginRight: (props: { drawerAnchor?: 'left' | 'right' }) =>
      props.drawerAnchor === 'right' ? drawerWidth : undefined,
  },
}))

export const useNavStyles = makeStyles(theme => ({
  navMenu: {
    padding: theme.spacing(0, 1),
    '& > ul': {
      display: 'flex',
      flexFlow: (props: { direction: 'row' | 'column' }) =>
        `${props.direction} nowrap`,
    },
  },
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexFlow: 'column nowrap',
    alignItems: (props: {
      alignItems?: 'flex-start' | 'center' | 'flex-end'
    }) => props.alignItems || 'flex-start',
    // alignContent: (props: NavItemProps) =>
    //   props.maxDepth ? 'flex-end' : 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'center',
  },
  button: {
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
  },
  buttonLeaf: {
    whiteSpace: 'nowrap',
    letterSpacing: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    textAlign: 'left',
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
    borderRadius: '3px',
    lineHeight: '24px',
    padding: '8px 6px 8px 6px',
  },
  active: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '120%',
  },
}))
