import * as _ from 'lodash'
import assert from 'assert'

import { BehaviorSubject, defer } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

import { Mdx, MdxEdge, Query } from '../../types/gatsby-graphql-types'
import { MdxCtlImpl } from './MdxCtl'

export type MdxBySlug = {
  [slug: string]: MdxCtlImpl
}

export class AllMdxService {
  private mdxBySlug: MdxBySlug = {}

  private mdxBySlug$: BehaviorSubject<MdxBySlug>

  constructor() {
    this.mdxBySlug$ = new BehaviorSubject<MdxBySlug>(this.mdxBySlug)
  }

  observeMdxBySlug() {
    // return this.mdxBySlug$.asObservable()
    return defer(() => this.mdxBySlug$.pipe(distinctUntilChanged(_.isEqual)))
  }

  getMdxBySlug() {
    return this.mdxBySlug
  }

  observeMdxCtl(props: { mdx?: Mdx; slug?: string }) {
    const { mdx, slug: slugRequested } = props
    let slug = slugRequested
    if (mdx) {
      assert(mdx.fields.slug)
      slug = mdx.fields.slug
    }
    assert(slug)
    let mdxCtl
    if (!(slug in this.mdxBySlug)) {
      mdxCtl = new MdxCtlImpl(slug)
      this.mdxBySlug[slug] = mdxCtl
    } else {
      mdxCtl = this.mdxBySlug[slug]
    }
    return mdxCtl.observe()
  }

  getMdxCtl(props: { mdx?: Mdx; slug?: string }) {
    const { mdx, slug: slugRequested } = props
    let slug = slugRequested
    if (mdx) {
      assert(mdx.fields.slug)
      slug = mdx.fields.slug
    }
    assert(slug)
    if (!(slug in this.mdxBySlug)) {
      return undefined
    }
    return this.mdxBySlug[slug]
  }

  setQuery(query: Query) {
    const mdxList: MdxCtlImpl[] = query.allMdx.edges.flatMap(
      (edge: MdxEdge) => {
        const frontmatter = _.get(edge, 'node.frontmatter', null)
        const route = _.get(edge, 'node.fields.route', null)
        const uid = _.get(edge, 'node.fields.uid', null)
        const slug = _.get(edge, 'node.fields.slug', null).replace('/pages', '')
        if (!uid || !slug || !frontmatter || route === null) {
          console.error(
            `mdx file ${edge.node.fileAbsolutePath} cannot be included as mandatory fields are missing
            please check the mdx query and/or 'gatsby-node.js' and make sure to include fields:
            frontmatter, route, slug, uid`
          )
          return []
        }
        let title = _.get(edge, 'node.fields.title', '')

        if (title.length === 0) {
          title = slug
            .split('/')
            .slice(-2, -1)
            .join('/')
        }
        // we may have cases where a given slug is observed prior to the MDX being
        // retrieved, in that case an entry may alreadt exist in this.mdxBySlug
        // however mdxCtl.mdx must be undefined - hence the assertion below.
        const mdxCtl =
          slug in this.mdxBySlug ? this.mdxBySlug[slug] : new MdxCtlImpl(slug)
        // mdx can be initialised only once
        // with storybook we have concurrent storybook decorators and hence the pages
        // may be initialised multiple times.
        // we hence just prevent the recreation instead of asserting and erroring out
        // assert(!mdxCtl.mdx)
        if (mdxCtl.mdx) {
          return []
        }
        mdxCtl.addMdx({ mdx: edge.node, uid, title, route, frontmatter })
        return [mdxCtl]
      }
    )
    // complete the mdxCtl creation by adding children
    mdxList.forEach((m: MdxCtlImpl) => {
      m.addChildren(mdxList)
    })

    // finally notify observers
    mdxList.forEach((m: MdxCtlImpl) => {
      m.notifyObservers()
      this.mdxBySlug[m.slug] = m
    })

    this.mdxBySlug$.next(this.mdxBySlug)
  }
}
