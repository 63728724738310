/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import { uid } from 'react-uid'

import { useTheme, Theme, makeStyles } from '@material-ui/core/styles'
import { useAppFrameState } from '../../../services'
import {
  PageProps,
  LayoutCfgProps,
  AppDrawerCfg,
} from '../../../types/interfaces'
import { useAppFrameStyles, useNavStyles } from './AppFrameStyle'
// iOS is hosted on high-end devices. We can enable the backdrop transition without
// dropping frames. The performance will be good enough.
// So: <SwipeableDrawer disableBackdropTransition={false} />
const iOS =
  typeof window !== 'undefined'
    ? process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    : false

export interface AppDrawerProps extends PageProps, AppDrawerCfg {}

export const getAppDrawer: (
  cfg: LayoutCfgProps
) => React.FunctionComponent<AppDrawerProps> = cfg => {
  const { withPrefix, Link } = cfg

  return React.useCallback((props: AppDrawerProps) => {
    const appDrawerCfg = props as AppDrawerCfg
    const pageProps = props as PageProps
    const { anchor, variant, clipped, mini, divider, content } = appDrawerCfg
    const classes = useAppFrameStyles({ drawerClipped: clipped, ...props })
    const classesNav = useNavStyles(props)

    const theme = useTheme()

    // const { onDrawerOpen, onDrawerClose } = PageProps
    const {
      drawerOpen = false,
      toggleDrawer,
      tryToggleDrawer,
    } = useAppFrameState()
    if (variant === 'disabled') {
      return null
    }
    let leftChevron = theme.direction === 'ltr' && anchor === 'left'
    leftChevron = theme.direction === 'rtl' ? !leftChevron : leftChevron
    const persistentCloseIcon = ['persistent'].includes(variant) ? (
      <>
        <div className={classNames(classesNav.navMenu)}>
          <IconButton onClick={toggleDrawer} className={classesNav.buttonLeaf}>
            {leftChevron ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
      </>
    ) : null
    const elems = (side: 'top' | 'bottom') =>
      content[side]?.map((Comp, idx) =>
        Comp ? <Comp key={uid(side, idx)} {...pageProps} /> : null
      ) ?? null
    const drawerContent = (
      <div className={classNames(classes.drawerContent, 'app-drawer')}>
        {clipped && !['temporary'].includes(variant) ? (
          <>
            <Toolbar />
            <Box p={2} />
          </>
        ) : null}
        {persistentCloseIcon}
        {elems('top')}
        {divider ? <Divider /> : null}
        {elems('bottom')}
      </div>
    )
    const swipeable = ['persistent', 'temporary'].includes(variant) ? (
      <SwipeableDrawer
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        anchor={anchor}
        disableDiscovery={iOS}
        disableBackdropTransition={!iOS}
        variant={variant}
        open={drawerOpen}
        onOpen={tryToggleDrawer}
        onClose={tryToggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent}
      </SwipeableDrawer>
    ) : null
    const regular = ['permanent'].includes(variant) ? (
      <Drawer
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        anchor={anchor}
        variant={variant}
      >
        {drawerContent}
      </Drawer>
    ) : null
    return (
      <>
        {swipeable}
        {regular}
      </>
    )
  }, [])
}
