import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Breakpoints } from '../types/interfaces'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

// https://material-ui.com/components/hidden/#how-it-works

// AppFrame breakpoints define the visible screen ranges (opposite of hidden)

// innerWidth  |xs      sm       md       lg       xl
//             |--------|--------|--------|--------|-------->
// width       |   xs   |   sm   |   md   |   lg   |   xl
// smUp        |   false | true
// mdDown      |                     true | false

// smDown      |           true  | false  | false
// mdDown      |           true  | true   | false
// lgUp        |           false | false  | true

// lgUp: {
//   conds: ['lgUp'],
//   negateConds: ['mdDown' = negative('lgUp)],
// },
// mdDown: {
//   conds: ['mdDown', 'mdUp' = negative('smDown')],
//   negateConds: ['lgUp' = negative('mdDown'), 'smDown'],
// },
// smDown: {
//   conds: ['smDown'],
//   negateConds: ['mdUp' = negative('smDown')],
// }
export const useBkpConditions = (opts: {
  bkpList: Breakpoints[]
  negate: boolean
  mediaQuery: boolean
}) => {
  const { bkpList, negate = false, mediaQuery = false } = opts
  const theme = useTheme()
  const reBkp = /^(.*)(Up|Down)$/
  const allBkpList = ['xs', 'sm', 'md', 'lg', 'xl']
  const splitNegative = (bkp: Breakpoints) => {
    const [, what, dir] = reBkp.exec(bkp)
    const idx = allBkpList.findIndex(b => b === what)
    if (dir === 'Up') {
      const lower = allBkpList[idx - 1] ?? what
      return { what: lower as Breakpoint, dir: 'Down' as 'Up' | 'Down' }
    }
    const upper = allBkpList[idx + 1] ?? what
    return { what: upper as Breakpoint, dir: 'Up' as 'Up' | 'Down' }
  }
  const split = (bkp: Breakpoints) => {
    const [, what, dir] = reBkp.exec(bkp)
    return { what: what as Breakpoint, dir: dir as 'Up' | 'Down' }
  }
  const getMediaQuery = (b: { what: Breakpoint; dir: 'Up' | 'Down' }) => {
    if (b.dir === 'Up') {
      return useMediaQuery(theme.breakpoints.up(b.what))
      // console.log(
      //   `!!!!!!!!!! useMediaQuery[up(${b.what})] ${theme.breakpoints.up(
      //     b.what
      //   )} = ${m}`
      // )
    }
    return useMediaQuery(theme.breakpoints.down(b.what))
  }
  const result = (b: { what: Breakpoint; dir: 'Up' | 'Down' }) => {
    return mediaQuery ? getMediaQuery(b) : `${b.what}${b.dir}`
  }
  const allCondsByBkp: Partial<Record<Breakpoints, (boolean | string)[]>> = {}
  bkpList.forEach((visibleBkp, visibleBkpIdx) => {
    const allConds = []
    allConds.push(
      result(negate ? splitNegative(visibleBkp) : split(visibleBkp))
    )
    if (visibleBkpIdx > 0 && visibleBkpIdx < bkpList.length - 1) {
      allConds.push(
        result(
          negate
            ? split(bkpList[visibleBkpIdx - 1])
            : splitNegative(bkpList[visibleBkpIdx - 1])
        )
      )
    }
    allCondsByBkp[visibleBkp] = allConds
  })
  return allCondsByBkp
}
