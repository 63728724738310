import * as _ from 'lodash'

import { BehaviorSubject } from 'rxjs'
import { Mdx, MdxFrontmatter } from '../../types/gatsby-graphql-types'

export interface MdxCtl {
  mdx: Mdx

  frontmatter: MdxFrontmatter

  route: boolean

  title: string

  slug: string

  uid: string

  path: string

  parent: MdxCtl

  children: MdxCtl[] // all mdx children under the slug

  sections: MdxCtl[] // children ordered and selected via the frontmatter field `sections`
}

export class MdxCtlImpl implements MdxCtl {
  mdx: Mdx

  frontmatter: MdxFrontmatter

  route: boolean

  title: string

  slug: string

  uid: string

  path: string

  parent: MdxCtlImpl

  children: MdxCtlImpl[] // all mdx children under the slug

  sections: MdxCtlImpl[] // children ordered and selected via the frontmatter field `sections`

  private mdx$: BehaviorSubject<MdxCtl>

  // note on mdx$:
  // the pattern is to emit each time an attribute is updated
  // In our case this happens only once at construction when the graphql
  // query is handled. But this pattern allows for dynamic updates in the future.

  constructor(slug: string) {
    this.slug = slug
    this.mdx$ = new BehaviorSubject<MdxCtl>(undefined)
  }

  observe() {
    return this.mdx$.asObservable()
  }

  addMdx(props: {
    mdx: Mdx
    uid: string
    title: string
    route: boolean
    frontmatter: MdxFrontmatter
  }) {
    const { mdx, uid, title, route, frontmatter } = props
    this.mdx = mdx
    this.title = title || mdx.fields.title
    this.uid = uid
    this.route = route
    this.frontmatter = frontmatter
    // note on the path field:
    // the ':' is a workaround due to a recent behavior of gatbsy graphql
    // which maps a path object instead of the string when the string
    // exactly matches a file. so to prevent the string to match we add a ':' prefix
    this.path = _.get(mdx, 'fields.relativePath', ':').slice(1)
  }

  addParent(mdx: MdxCtlImpl) {
    this.parent = mdx
  }

  addChildren(mdxList: MdxCtlImpl[]) {
    const depth = this.slug.split('/').length
    this.children = mdxList.filter(
      (c: MdxCtl) =>
        c !== this &&
        c.slug.search(this.slug) === 0 &&
        c.slug.split('/').length === depth + 1
    )
    // update the parent for each identified children
    this.children.forEach(c => {
      c.addParent(this)
    })
    // optionally when the frontmatter field 'sections' is provided:
    // further order / filter-out
    const sections = _.get(this.mdx, 'frontmatter.sections', null)

    if (sections === null) {
      // if sections is just not provided, we use the default children list
      this.sections = this.children
      return
    }
    if (!(sections instanceof Array)) {
      console.error(
        `frontmatter field 'sections' invalid for mdx ${
          this.slug
        }: ${JSON.stringify(sections)}`
      )
      this.sections = this.children
      return
    }
    this.sections = []
    sections.forEach((name: string) => {
      const [child] = this.children.filter(c => c.uid === name)
      if (child) {
        this.sections.push(child)
      } else {
        console.error(
          `Error in building ordered TOC for ${this.slug}: cannot find ${name} as a child`
        )
      }
    })
  }

  notifyObservers() {
    this.mdx$.next(this as MdxCtl)
  }
}
