import * as React from 'react'
import { uid } from 'react-uid'
import classNames from 'classnames'

import List from '@material-ui/core/List'

import { LinkProps, PageProps } from '../../../types/interfaces'
import { PageContext, useAllPage, useAppFrameState } from '../../../services'
import { getNavItem } from './NavItem'
import { useNavStyles } from '../LayoutMUI/AppFrameStyle'

let savedScrollTop: number = null
const PersistScroll: React.FunctionComponent<React.ComponentPropsWithRef<
  'div'
>> = props => {
  const { className, children } = props
  const rootRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const parent = rootRef.current?.parentElement ?? null
    const activeElement = document.querySelector('.drawer-active')

    if (!parent || !activeElement || !activeElement.scrollIntoView) {
      return undefined
    }

    const activeBox = activeElement.getBoundingClientRect()

    if (savedScrollTop === null || activeBox.top - savedScrollTop < 0) {
      // Center the selected item in the list container.
      activeElement.scrollIntoView()
      // Fix a Chrome issue, reset the tabbable ring back to the top of the document.
      document.body.scrollIntoView()
    } else {
      parent.scrollTop = savedScrollTop
    }

    return () => {
      savedScrollTop = parent.scrollTop
    }
  }, [])

  return (
    <div className={className} ref={rootRef}>
      {children}
    </div>
  )
}

interface RenderNavItemsProps {
  pages: PageContext[]
  activePage: PageContext
  depth: number
  maxDepth: number
  onClose: () => void
}
export interface NavMenuCfgProps {
  Link: React.FunctionComponent<LinkProps>
  className?: string
  direction?: 'column' | 'row'
  depth?: number // by default use 0 - i.e. do not display children navigation
  pathnames?: (string | PageContext)[] // by default use mainPages
  mini?: boolean
  divider?: boolean
  collapseDepth?: number
  alignItems?: 'flex-start' | 'flex-end' | 'center'
  adaptPage?: (p: PageContext) => PageContext
}
export const getNavMenu: (
  cfg: NavMenuCfgProps
) => React.FunctionComponent<PageProps> = cfg => {
  const {
    Link,
    depth: maxDepthCfg = 0,
    pathnames,
    direction = 'column',
    mini,
    divider,
    collapseDepth,
    adaptPage,
    className,
    alignItems = 'flex-start',
  } = cfg
  const NavItem = getNavItem({ Link })
  const reduceChildRoutes = React.useCallback(
    (props: {
      items: React.ReactNode[]
      page: PageContext
      activePage: PageContext
      depth: number
      maxDepth: number
      onClose: () => void
    }) => {
      const { items, page, activePage, depth, maxDepth, onClose } = props
      const { title, Icon, route, mdxCtl } = adaptPage ? adaptPage(page) : page
      const hasChildren = page.children && page.children.length > 0
      const hasNavChildren = hasChildren && depth < maxDepth
      // console.log(
      //   `${title} depth=${depth} hasNav=${hasNavChildren} route=${route}`
      // )
      if (!route) {
        return items
      }
      const topLevel = activePage
        ? activePage.pathname.indexOf(`${page.pathname}/`) === 0
        : false
      // we navigate to the page when it does not have children
      // or when it has children, we navigate to the page only if its content is not empty
      const notEmpty = mdxCtl && mdxCtl.mdx.excerpt.length > 0
      const navigatedPath =
        !hasNavChildren || (hasNavChildren && notEmpty)
          ? page.pathname
          : undefined
      items.push(
        <NavItem
          Icon={Icon}
          depth={depth}
          maxDepth={maxDepthCfg}
          collapseDepth={collapseDepth}
          key={uid(page)}
          topLevel={topLevel}
          openImmediately={topLevel}
          title={title}
          href={navigatedPath}
          mini={mini}
          divider={divider}
          onClick={onClose}
          alignItems={alignItems}
        >
          {hasNavChildren ? (
            <RenderNavItems
              pages={page.children}
              // key={uid(page.children)}
              activePage={activePage}
              depth={depth + 1}
              maxDepth={maxDepthCfg}
              onClose={onClose}
            />
          ) : null}
        </NavItem>
      )

      return items
    },
    [NavItem] // useCallback
  )

  const RenderNavItems: React.FunctionComponent<RenderNavItemsProps> = React.useCallback(
    props => {
      const { pages, activePage, depth, maxDepth, onClose } = props

      return (
        <List disablePadding>
          {pages.reduce(
            (items, page) =>
              reduceChildRoutes({
                items,
                page,
                activePage,
                depth,
                maxDepth,
                onClose,
              }),
            []
          )}
        </List>
      )
    },
    [NavItem] // useCallback
  )
  return React.useCallback(
    (props: PageProps) => {
      const { location } = props
      const { tryToggleDrawer } = useAppFrameState()

      const { activePage, mainPages, pageBySlug } = useAllPage({ location })
      const classes = useNavStyles({ direction })
      if (!activePage || !mainPages || !pageBySlug) {
        return null
      }
      let renderedPages
      if (pathnames) {
        renderedPages = pathnames.flatMap(p => {
          const slug = (p as PageContext)?.pathname ?? (p as string)
          return slug in pageBySlug ? [pageBySlug[slug]] : []
        })
      } else {
        renderedPages = mainPages
      }
      return (
        <PersistScroll className={classNames(classes.navMenu, className)}>
          <RenderNavItems
            pages={renderedPages}
            activePage={activePage}
            depth={0}
            maxDepth={maxDepthCfg}
            onClose={tryToggleDrawer}
          />
        </PersistScroll>
      )
    },
    [NavItem]
  )
}
