/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import classNames from 'classnames'

import { uid } from 'react-uid'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Zoom from '@material-ui/core/Zoom'
import Container from '@material-ui/core/Container'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { PageProps, LayoutCfgProps, AppBarCfg } from '../../../types/interfaces'
import { useAppFrameStyles } from './AppFrameStyle'
import { useAppFrameState } from '../../../services'

interface ScrollProps {
  children: React.ReactElement
}

function HideOnScroll(props: ScrollProps) {
  const { children } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
function ElevationScroll(props: ScrollProps) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

interface ScrollTopProps extends ScrollProps {
  anchor: string
}

function ScrollTop(props: ScrollTopProps) {
  const { anchor, children } = props
  const classes = useAppFrameStyles()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchorElement = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector(`#${anchor}`)

    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollTop}
      >
        {children}
      </div>
    </Zoom>
  )
}

export interface AppBarProps extends PageProps, AppBarCfg {}

export const getAppBar: (
  cfg: LayoutCfgProps
) => React.FunctionComponent<AppBarProps> = cfg => {
  const { withPrefix } = cfg

  return React.useCallback((props: AppBarProps) => {
    const { children, location } = props
    const appBarCfg = props as AppBarCfg
    const scrollTopAnchor = `back-to-top-anchor-${uid(appBarCfg)}`
    const {
      anchor = 'top',
      variant = 'regular',
      hideOnScrollDown = false,
      elevateOnScroll = false,
      backToTop = false,
      content,
      Header,
      Footer,
    } = appBarCfg
    const elems = (side: 'left' | 'right') =>
      content[side]?.map((Comp, idx) =>
        Comp ? <Comp key={uid(side, idx)} location={location} /> : null
      ) ?? null
    const { drawerOpen, appDrawerCfg } = useAppFrameState()
    const {
      clipped: drawerClipped,
      variant: drawerVariant,
      anchor: drawerAnchor,
    } = appDrawerCfg
    const needShift =
      drawerVariant === 'permanent' ||
      (drawerOpen && drawerVariant === 'persistent')
    const classes = useAppFrameStyles({
      drawerClipped,
      drawerAnchor,
      ...props,
    })
    const appBar = (
      <AppBar
        position="sticky"
        className={classNames(
          classes.appBar,
          'app-bar',
          anchor === 'bottom' ? classes.bottom : null,
          {
            [classes.appBarShift]: needShift && !drawerClipped,
          }
        )}
      >
        <Toolbar
          className={classNames(classes.toolbar, 'container-fluid')}
          variant={variant}
          disableGutters
        >
          {elems('left')}
          {elems('right')}
        </Toolbar>
      </AppBar>
    )
    const appBar2 = hideOnScrollDown ? (
      <HideOnScroll>{appBar}</HideOnScroll>
    ) : (
      appBar
    )
    const appBar3 = elevateOnScroll ? (
      <ElevationScroll>{appBar2}</ElevationScroll>
    ) : (
      appBar2
    )
    const scrollTop = backToTop ? (
      <ScrollTop anchor={scrollTopAnchor}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    ) : null
    return (
      <div style={{ minWidth: '100%' }}>
        {Header ? <Header location={location} /> : null}
        {appBar}
        <div className={classNames(classes.content)}>
          {/* we add an empty toolbar to correct the issue of fixed positioning
              described at  https://material-ui.com/components/app-bar/ */}
          <Toolbar id={scrollTopAnchor} />
          <Container
            className={classNames('container-fluid', 'app-content', {
              [classes.contentShift]: needShift,
            })}
          >
            {children}
            {scrollTop}
            {Footer ? <Footer location={location} /> : null}
          </Container>
        </div>
      </div>
    )
  }, [])
}
