/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
import * as React from 'react'
import * as _ from 'lodash'

import { uid } from 'react-uid'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import { Link } from '../../components/ui/Link'
import { MdxCtl, useMdxCtl } from '../../index'

const caretRight = '"\\25B8"'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      '& a:before': {
        content: caretRight,
        paddingRight: '8px',
        color: theme.palette.primary.light,
      },
    },
  })
)
export interface BlogIndexProps {
  slug: string
}
export const BlogIndex = (props: BlogIndexProps) => {
  const classes = useStyles({})
  const { slug } = props
  const [mdxCtl] = useMdxCtl({ slug })
  if (!mdxCtl || !mdxCtl.route) {
    return null
  }
  if (mdxCtl.parent) {
    // list only the top-level blogs in the index
    return null
  }
  return (
    <List className={classes.root}>
      {mdxCtl.sections.map((m: MdxCtl, idx: number) => {
        const excerpt = _.get(mdxCtl.mdx, 'excerpt', null)
        return (
          <div key={uid(m, idx)}>
            <ListItem key={uid(m, idx)}>
              <ListItemText
                primary={
                  <h3>
                    <Link to={m.slug} style={{ boxShadow: `none` }}>
                      {m.title}
                    </Link>
                  </h3>
                }
                secondary={
                  excerpt ? (
                    <Typography variant="body1">
                      {/* {m.frontmatter.date}
                          <br /> */}
                      {excerpt}
                    </Typography>
                  ) : null
                }
              />
            </ListItem>
          </div>
        )
      })}
    </List>
  )
}
