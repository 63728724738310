// import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { gatsbyHighlight, gatsbyHighlightLanguageBadges } from '@cbeyond/ui-kit'

const palette = {
  bluePastel: '#cfd8dc',
  grey: '#616161',
  pink: '#ccb9bc',
  offWhite: '#EDEDEE',
  white: '#FFF',
  beige: '#F9F6F4'
}

interface FrameSectionStyle {
  gradient?: string
  backgroundColor: string
  text: string
  lightText: string
  contrastText: string
}

const mainMenuStyle: FrameSectionStyle = {
  // gradient,
  backgroundColor: palette.bluePastel,
  text: palette.grey,
  lightText: palette.pink,
  contrastText: palette.grey
}
const navMenuStyle: FrameSectionStyle = mainMenuStyle

const bodyStyle: FrameSectionStyle = {
  // gradient,
  backgroundColor: palette.white,
  text: palette.grey,
  lightText: palette.pink,
  contrastText: palette.grey
}

const prismHighlights = palette.beige

let theme = createMuiTheme({
  palette: {
    type: 'light',
    text: {
      primary: bodyStyle.text,
      secondary: mainMenuStyle.text
    },
    primary: {
      main: bodyStyle.text,
      light: bodyStyle.lightText,
      contrastText: bodyStyle.contrastText
    },
    secondary: {
      // use as the prism code highlighting background
      main: prismHighlights
      // main: mainMenuStyle.text,
      // light: mainMenuStyle.lightText,
      // contrastText: mainMenuStyle.contrastText
    },
    background: {
      default: bodyStyle.backgroundColor,
      paper: bodyStyle.backgroundColor
    }
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    // fontFamily: ['Poppins', 'sans-serif'].join(','),
    // fontSize: 12
    h1: {
      fontSize: '50%'
    },
    h2: {
      fontSize: '50%'
    }
  }
})
theme = responsiveFontSizes(theme)
// apply a gradient background if defined, else applies background color
const getBackgroundStyle = (props: FrameSectionStyle) => {
  if (props.gradient) {
    return {
      backgroundSize: 'cover',
      height: '100%',
      background: props.gradient
    }
  }
  return {
    backgroundColor: props.backgroundColor
  }
}

const applySectionStyle = (props: FrameSectionStyle) => ({
  color: props.text,
  '& li': {
    color: props.contrastText
  },
  '& span.MuiButton-label': {
    color: props.contrastText
    // fontSize: '130%',
    // fontWeight: '500'
  },
  '& svg.MuiSvgIcon-root': {
    color: props.contrastText
  },
  '& .hamburger span.hamburger-inner': {
    backgroundColor: props.contrastText,
    '&:before': {
      backgroundColor: props.contrastText
    },
    '&:after': {
      backgroundColor: props.contrastText
    }
  },
  ...getBackgroundStyle(props)
})
// const applyBodySectionStyle = (props: FrameSectionStyle) => ({
//   color: props.text,
//   ...getBackgroundStyle(props)
// })

const body = {
  '@global': {
    html: {
      height: '100%' // allows body background to cover the viewport height
    },
    body: applySectionStyle(bodyStyle),
    'header.app-bar': {
      backgroundColor: mainMenuStyle.backgroundColor
    },
    '.app-bar': {
      ...applySectionStyle(mainMenuStyle)
    },
    '.app-drawer': {
      margin: 0,
      paddingTop: theme.spacing(2),
      ...applySectionStyle(mainMenuStyle)
    },
    '.app-content': {
      padding: theme.spacing(4, 2)
    },
    '.nav-menu': {
      '& a.MuiLink-root.active': {
        '& span.MuiButton-label': {
          fontWeight: theme.typography.fontWeightMedium
        }
      },
      ...applySectionStyle(navMenuStyle)
    },
    '.main-menu': {},
    '.mdx-root': {}
  }
}
const width = {
  '@global': {
    '.container-fluid': {
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '100%',
      maxWidth: '95vw',
      '@media (min-width: 576px)': {
        maxWidth: '576px !important'
      },
      '@media (min-width: 768px)': {
        maxWidth: '768px !important'
      },
      '@media (min-width: 992px)': {
        maxWidth: '992px !important'
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1200px !important'
      },
      '@media (min-width: 1500px)': {
        maxWidth: '1500px !important'
      }
    }
  }
}

const gh = gatsbyHighlight(theme)
const gl = gatsbyHighlightLanguageBadges(theme)
const scroll = {
  '@global': {
    '*::-webkit-scrollbar': {
      width: theme.spacing(1),
      height: theme.spacing(1)
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.dark,
      outline: '1px solid slategrey'
    }
  }
}

const themeFinal = createMuiTheme({
  ...theme,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ...body['@global'],
        ...width['@global'],
        ...scroll['@global'],
        ...gl['@global'],
        ...gh['@global']
      }
    }
  }
})

export default themeFinal
