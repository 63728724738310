// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activites-mdx": () => import("./../../../src/pages/activites.mdx" /* webpackChunkName: "component---src-pages-activites-mdx" */),
  "component---src-pages-carte-mdx": () => import("./../../../src/pages/carte.mdx" /* webpackChunkName: "component---src-pages-carte-mdx" */),
  "component---src-pages-contact-us-mdx": () => import("./../../../src/pages/contact-us.mdx" /* webpackChunkName: "component---src-pages-contact-us-mdx" */),
  "component---src-pages-frontmatter-mdx": () => import("./../../../src/pages/frontmatter.mdx" /* webpackChunkName: "component---src-pages-frontmatter-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-infos-mdx": () => import("./../../../src/pages/infos.mdx" /* webpackChunkName: "component---src-pages-infos-mdx" */),
  "component---src-pages-surfaces-mdx": () => import("./../../../src/pages/surfaces.mdx" /* webpackChunkName: "component---src-pages-surfaces-mdx" */),
  "component---src-pages-visite-mdx": () => import("./../../../src/pages/visite.mdx" /* webpackChunkName: "component---src-pages-visite-mdx" */)
}

